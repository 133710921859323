var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',[_c('div',{staticClass:"d-flex flex-column flex-shrink-0 p-3 text-white bg-dark",staticStyle:{"width":"260px"}},[_vm._m(0),_c('hr'),_c('ul',{staticClass:"nav nav-pills flex-column mb-auto menu-item"},[_c('li',[_c('a',{staticClass:"nav-link text-white",class:{'active' : _vm.$route.name === 'dashboard'},attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.$router.push({name: 'dashboard'})}}},[_c('i',{staticClass:"la la-dashboard"}),_vm._v(" Dashboard ")])]),_c('li',[_c('a',{staticClass:"nav-link text-white",class:{'active' : _vm.$route.name === 'clients'},attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.$router.push({name: 'clients'})}}},[_c('i',{staticClass:"la la-users"}),_vm._v(" Clientes ")])]),_c('li',[_c('a',{staticClass:"nav-link text-white",class:{'active' : _vm.$route.name === 'portals'},attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.$router.push({name: 'portals'})}}},[_c('i',{staticClass:"la la-project-diagram"}),_vm._v(" Portais ")])])]),_c('hr'),_c('div',{staticClass:"dropdown"},[_vm._m(1),_c('ul',{staticClass:"dropdown-menu dropdown-menu-dark text-small shadow"},[_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('li',[_c('a',{staticClass:"dropdown-item",on:{"click":function($event){$event.preventDefault();return _vm.logout.apply(null, arguments)}}},[_vm._v("Sign out")])])])])]),_c('div',{staticClass:"main-content"},[_vm._t("default")],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"d-flex align-items-center mb-3 me-md-auto text-white text-decoration-none mt-3",attrs:{"href":"/"}},[_c('img',{staticClass:"sidebar-logo ms-3",attrs:{"src":require("@/assets/logo-white.svg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"d-flex align-items-center text-white text-decoration-none dropdown-toggle",attrs:{"href":"#","id":"dropdownUser","data-bs-toggle":"dropdown"}},[_c('img',{staticClass:"rounded-circle me-2 bg-light",attrs:{"src":require("@/assets/usuario.svg"),"alt":"","width":"35","height":"35"}}),_c('strong',[_vm._v("Emerson Dário")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"dropdown-item",attrs:{"href":"#"}},[_vm._v("New project...")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"dropdown-item",attrs:{"href":"#"}},[_vm._v("Settings")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"dropdown-item",attrs:{"href":"#"}},[_vm._v("Profile")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('hr',{staticClass:"dropdown-divider"})])
}]

export { render, staticRenderFns }