import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import auth from "./auth";
import Clients from "@/store/Clients";
import Portals from "@/store/Portals";

Vue.use(Vuex)

export default new Vuex.Store({
    plugins: [createPersistedState({
        key: 'imob_admin',
        paths: ['auth']
    })],
    state: {},
    mutations: {},
    actions: {},
    modules: {
        auth,
        Clients,
        Portals
    }
})
