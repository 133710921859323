import axios from "axios";
import store from "@/store";
import router from "@/router/index";

export default function axiosSetUp() {
    axios.defaults.baseURL = process.env.VUE_APP_API_URL;
    axios.interceptors.request.use(
        function (config) {
            const token = store.getters["auth/accessToken"];
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
            config.headers.accept = 'application/json'
            return config;
        },
        function (error) {
            return Promise.reject(error);
        }
    );

    axios.interceptors.response.use(
        function (response) {
            return response;
        },
        async function (error) {
            const originalRequest = error.config;
            if (
                error.response.status === 401 &&
                originalRequest.url.includes("/auth/refresh")
            ) {
                store.commit("auth/clearUserData");
                await router.push("/login");
                return Promise.reject(error);
            } else if (error.response.status === 401 && !originalRequest._retry && !originalRequest.url.includes('/auth/login')) {
                originalRequest._retry = true;
                await store.dispatch("auth/refreshToken");
                return axios(originalRequest);
            }
            return Promise.reject(error);
        }
    );

    return axios;
}