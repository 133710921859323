import router from "@/router";
import axios from "axios";

export default {
    namespaced: true,
    state: {
        access_token: "",
        loggedInUser: {},
        isAuthenticated: false
    },
    mutations: {
        setAccessToken(state, accessToken) {
            state.access_token = accessToken;
            state.isAuthenticated = true;
        },

        setLoggedInUser(state, user) {
            state.loggedInUser = user;
        },
        clearUserData(state) {
            state.refresh_token = "";
            state.access_token = "";
            state.loggedInUser = {};
            state.isAuthenticated = false;
        }
    },
    actions: {
        login: async ({commit, dispatch}, payload) => {
            const loginUrl = "/auth/login";
            return await axios.post(loginUrl, payload).then(response => {
                if (response.status === 200) {
                    commit("setAccessToken", response.data.access_token);
                    dispatch("fetchUser");
                    router.push('/');
                }
            })
        },
        logout: async ({commit, dispatch}) => {
            return axios.post('/auth/logout').finally(() => {
                commit("clearUserData");
                router.push('/login');
            });
        },
        refreshToken: async ({state, commit}) => {
            const refreshUrl = "/auth/refresh";
            try {
                await axios.post(refreshUrl).then(response => {
                    if (response.status === 200) {
                        commit("setAccessToken", response.data.access_token);
                    }
                });
            } catch (e) {
                console.error(e.response);
            }
        },
        fetchUser: async ({commit}) => {
            const currentUserUrl = "/auth/me";
            try {
                await axios.post(currentUserUrl).then(response => {
                    if (response.status === 200) {
                        commit("setLoggedInUser", response.data.data);
                    }
                });
            } catch (e) {
                console.error(e.response);
            }
        }
    },
    getters: {
        loggedInUser: state => state.loggedInUser,
        isAuthenticated: state => state.isAuthenticated,
        accessToken: state => state.access_token,
    }
}