<template>
  <main>
    <div class="d-flex flex-column flex-shrink-0 p-3 text-white bg-dark" style="width: 260px;">
      <a href="/" class="d-flex align-items-center mb-3 me-md-auto text-white text-decoration-none mt-3">
        <img src="@/assets/logo-white.svg" class="sidebar-logo ms-3"/>
      </a>
      <hr>
      <ul class="nav nav-pills flex-column mb-auto menu-item">
        <li>
          <a
              href="#"
              class="nav-link text-white"
              :class="{'active' : $route.name === 'dashboard'}"
              @click.prevent="$router.push({name: 'dashboard'})"
          >
            <i class="la la-dashboard"/>
            Dashboard
          </a>
        </li>
        <li>
          <a
              href="#"
              class="nav-link text-white"
              :class="{'active' : $route.name === 'clients'}"
              @click.prevent="$router.push({name: 'clients'})"
          >
            <i class="la la-users"/>
            Clientes
          </a>
        </li>
        <li>
          <a
              href="#"
              class="nav-link text-white"
              :class="{'active' : $route.name === 'portals'}"
              @click.prevent="$router.push({name: 'portals'})"
          >
            <i class="la la-project-diagram"/>
            Portais
          </a>
        </li>
      </ul>
      <hr>
      <div class="dropdown">
        <a href="#" class="d-flex align-items-center text-white text-decoration-none dropdown-toggle" id="dropdownUser"
           data-bs-toggle="dropdown">
          <img src="@/assets/usuario.svg" alt="" width="35" height="35" class="rounded-circle me-2 bg-light">
          <strong>Emerson Dário</strong>
        </a>
        <ul class="dropdown-menu dropdown-menu-dark text-small shadow">
          <li><a class="dropdown-item" href="#">New project...</a></li>
          <li><a class="dropdown-item" href="#">Settings</a></li>
          <li><a class="dropdown-item" href="#">Profile</a></li>
          <li>
            <hr class="dropdown-divider">
          </li>
          <li><a class="dropdown-item" @click.prevent="logout">Sign out</a></li>
        </ul>
      </div>
    </div>

    <div class="main-content">
      <slot></slot>
    </div>

  </main>
</template>
<script>
import {mapActions} from "vuex";

export default {
  name: 'default-layout',
  props: {},
  data() {
    return {}
  },
  methods: {
    ...mapActions('auth', ['logout'])
  }
}
</script>
<style lang="scss" scoped>

.dropdown-toggle {
  outline: 0;
}

.menu-item li i.la {
  font-size: 1.5rem;
  vertical-align: -.100em;
  pointer-events: none;
  cursor: pointer;
  margin-right: 5px;
}

.sidebar-logo {
  width: 150px;
}

.main-content {
  padding: 1.5rem 2.5rem;
  width: 100%;
}
</style>
